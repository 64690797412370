import React from 'react'
import { Link } from 'gatsby'
import {
  AiFillTwitterSquare,
  AiFillInstagram,
  AiFillFacebook
} from 'react-icons/ai'
const links = [
  { text: 'Privacy Policy', link: '/privacy-policy' },
  { text: 'Affiliate Disclosure', link: '/affiliate-disclosure' },
  { text: 'Terms of Service', link: '/terms-of-service' }
]
const footer = () => {
  return (
    <footer>
      <section className='hero is-light is-bold mt-6'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <h5 className='title'>Find More!</h5>
            <div style={{ textAlign: 'center' }}>
              <a href='https://www.facebook.com/toyreads/'>
                <AiFillFacebook style={{ fontSize: '45px' }} />
              </a>
              <a href='https://www.instagram.com/toyreads/'>
                <AiFillInstagram style={{ fontSize: '45px' }} />
              </a>
              <a href='https://twitter.com/toyreads'>
                <AiFillTwitterSquare style={{ fontSize: '45px' }} />
              </a>
            </div>

            {links.map((e, i) => (
              <Link key={i} to={e.link} className='mx-2'>
                {e.text}
              </Link>
            ))}
          </div>
        </div>
      </section>

      <section className='hero is-small has-text-centered is-link is-bold'>
        <div className='hero-body'>
          <div className='container'>
            <h5>
              © {new Date().getFullYear()}, All Rights Reserved TOYREADS.COM
            </h5>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default footer
