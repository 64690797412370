import React from 'react'
import PlayBanner from '../svg/PlayBanner'
const hero = () => {
  return (
    <>
      <div className='container'>
        <div className='columns is-vcentered'>
          <div className='column has-text-centered'>
            <h1 className='is-size-1'>TOYREADS</h1>
            <h5 className='is-size-5 is-italic is-family-monospace'>
              bring me all the fun in the world!
            </h5>
          </div>
          <div className='column'>
            <PlayBanner />
          </div>
        </div>
      </div>
    </>
  )
}

export default hero
