import React from 'react'
import NavBar from './NavBar'
import Footer from './Footer'
import Hero from './Hero'
import '../scss/index.scss'

const Layout = ({ children, title = '', subtitle = '', isRoot = false }) => {
  return (
    <>
      {isRoot == true ? (
        <>
          <div className='hero is-primary is-medium is-fullheight-with-navbar'>
            <div className='hero-head'>
              <NavBar />{' '}
            </div>
            <div className='hero-body'>
              <Hero />
            </div>
          </div>
          {children}
        </>
      ) : (
        <>
          <NavBar />
          {title && subtitle && (
            <section className='hero is-link mt-6'>
              <div className='hero-body'>
                <div className='container has-text-centered'>
                  <h1 className='title'>{title}</h1>
                  <h2 className='subtitle'>{subtitle}</h2>
                </div>
              </div>
            </section>
          )}

          {title && subtitle ? (
            <section className='section' style={{ minHeight: '85vh' }}>
              <div className='container'>{children}</div>
            </section>
          ) : (
            <>{children}</>
          )}
        </>
      )}
      <Footer />
    </>
  )
}

export default Layout
