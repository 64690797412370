import * as React from 'react'

function SvgComponent (props) {
  return (
    <svg
      className='prefix__animated'
      id='toy_reads_playing'
      viewBox='0 0 500 500'
      {...props}
    >
      <style>
        {
          '@keyframes slideUp{0%{opacity:0;transform:translateY(30px)}to{opacity:1;transform:inherit}}@keyframes zoomIn{0%{opacity:0;transform:scale(.5)}to{opacity:1;transform:scale(1)}}@keyframes lightSpeedRight{60%{transform:skewX(10deg);opacity:1}80%{transform:skewX(-2deg)}}@keyframes fadeIn{0%{opacity:0}to{opacity:1}}@keyframes slideLeft{0%{opacity:0;transform:translateX(-30px)}to{opacity:1;transform:translateX(0)}}@keyframes slideRight{0%{opacity:0;transform:translateX(30px)}to{opacity:1;transform:translateX(0)}}svg#prefix__toy_reads_stories-children:not(.prefix__animated) .prefix__animable{opacity:0}svg#prefix__toy_reads_stories-children.prefix__animated #prefix__toy_reads--soccer-ball--inject-63{animation:1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideLeft;animation-delay:0s}'
        }
      </style>
      <g
        id='prefix__toy_reads--Floor--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '248.3px 346.75px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideUp',
          animationDelay: '0s'
        }}
      >
        <ellipse
          id='prefix__toy_reads--floor--inject-63'
          cx={248.3}
          cy={346.75}
          rx={223.26}
          ry={131.62}
          style={{
            transformOrigin: '248.3px 346.75px'
          }}
          className='prefix__animable'
          fill='#f19066'
        />
      </g>
      <g
        id='prefix__toy_reads--Shadows--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '250.22px 377.38px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomIn',
          animationDelay: '0s'
        }}
      >
        <ellipse
          cx={171.07}
          cy={433.07}
          rx={17.91}
          ry={10.34}
          style={{
            transformOrigin: '171.07px 433.07px'
          }}
          className='prefix__animable'
          opacity={0.1}
          id='prefix__toy_reads--Shadow--inject-63'
        />
        <ellipse
          cx={401.31}
          cy={345.1}
          rx={44.42}
          ry={25.65}
          style={{
            transformOrigin: '401.31px 345.1px'
          }}
          className='prefix__animable'
          opacity={0.07}
          id='prefix__toy_reads--shadow--inject-63'
        />
        <ellipse
          cx={100.87}
          cy={338}
          rx={46.16}
          ry={26.65}
          style={{
            transformOrigin: '100.87px 338px'
          }}
          className='prefix__animable'
          opacity={0.07}
          id='prefix__toy_reads--shadow--inject-63'
        />
        <ellipse
          cx={258.6}
          cy={336.99}
          rx={27.43}
          ry={15.84}
          style={{
            transformOrigin: '258.6px 336.99px'
          }}
          className='prefix__animable'
          opacity={0.07}
          id='prefix__toy_reads--shadow--inject-63'
        />
      </g>
      <g
        id='prefix__toy_reads--Plants--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '251.133px 279.804px',
          animation:
            '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) lightSpeedRight',
          animationDelay: '0s'
        }}
      >
        <g
          id='prefix__toy_reads--plants--inject-63'
          className='prefix__animable'
          style={{
            transformOrigin: '468.341px 286.103px'
          }}
        >
          <path
            d='M455.44 314.93l-6.6-5.4s-.85-13.81 1-25.35c2.55-16.23 13.85-35 26.15-29.7s-7 20.89-11.86 34.49-8.69 25.96-8.69 25.96z'
            style={{
              transformOrigin: '464.334px 284.248px'
            }}
            id='prefix__el7yeu4hvcth'
            className='prefix__animable'
            fill='#f19066'
          />
          <path
            d='M455.44 314.93l-6.6-5.4s-.85-13.81 1-25.35c2.55-16.23 13.85-35 26.15-29.7s-7 20.89-11.86 34.49-8.69 25.96-8.69 25.96z'
            style={{
              transformOrigin: '464.334px 284.248px'
            }}
            className='prefix__animable'
            opacity={0.1}
            id='prefix__elgwrr85wtf7'
          />
          <path
            d='M451.41 312a.42.42 0 00.43-.41c.86-36.33 19.21-52 19.4-52.14a.44.44 0 000-.6.43.43 0 00-.6 0c-.2.17-18.82 16.07-19.69 52.76a.42.42 0 00.46.39z'
            style={{
              transformOrigin: '461.154px 285.365px'
            }}
            id='prefix__elnznd0455ffo'
            className='prefix__animable'
            fill='#fff'
          />
          <path
            d='M460 318.64l-4.61-3.7a51.8 51.8 0 014.29-23.47c5.34-12.26 17.66-18.4 23-16.21 6.57 2.66 6.24 9.08 3.56 13.16-3.13 4.8-21.82 16.35-26.24 30.22z'
            style={{
              transformOrigin: '471.679px 296.732px'
            }}
            id='prefix__eljqrdeuq9son'
            className='prefix__animable'
            fill='#f19066'
          />
          <path
            d='M460 318.64l-4.61-3.7a51.8 51.8 0 014.29-23.47c5.34-12.26 17.66-18.4 23-16.21 6.57 2.66 6.24 9.08 3.56 13.16-3.13 4.8-21.82 16.35-26.24 30.22z'
            style={{
              transformOrigin: '471.679px 296.732px'
            }}
            className='prefix__animable'
            opacity={0.3}
            id='prefix__eloecktf4m0ub'
          />
          <path
            d='M457.43 317.4a.44.44 0 00.45-.4c1.22-22.93 23.64-37.18 23.86-37.32a.42.42 0 00.14-.58.43.43 0 00-.59-.14c-.23.15-23 14.63-24.26 38a.41.41 0 00.4.44z'
            style={{
              transformOrigin: '469.486px 298.148px'
            }}
            id='prefix__el2vpbttildpw'
            className='prefix__animable'
            fill='#fff'
          />
        </g>
        <g
          id='prefix__toy_reads--plants--inject-63'
          className='prefix__animable'
          style={{
            transformOrigin: '35.5498px 279.804px'
          }}
        >
          <path
            d='M50.73 304.27l6.14-5s.23-18.51-4.81-33.54c-7.09-21.13-22.62-27.1-27.63-24s-5.69 10.67 6.12 21.58c6.97 6.38 16.97 20.78 20.18 40.96z'
            style={{
              transformOrigin: '38.9423px 272.617px'
            }}
            id='prefix__ellu1y83en6l'
            className='prefix__animable'
            fill='#f19066'
          />
          <g
            style={{
              transformOrigin: '38.9423px 272.617px'
            }}
            className='prefix__animable'
            opacity={0.1}
            id='prefix__elf0dk9haos1t'
          >
            <path
              d='M50.73 304.27l6.14-5s.23-18.51-4.81-33.54c-7.09-21.13-22.62-27.1-27.63-24s-5.69 10.67 6.12 21.58c6.97 6.38 16.97 20.78 20.18 40.96z'
              id='prefix__elvmxvfoi099r'
              className='prefix__animable'
              style={{
                transformOrigin: '38.9423px 272.617px'
              }}
            />
          </g>
          <path
            d='M51.74 295.23h-.07a.5.5 0 01-.5-.48c-.62-20.8-12.55-41.69-23.25-49.11a.48.48 0 01-.12-.68.49.49 0 01.68-.13c10.89 7.56 23 28.79 23.67 49.89a.49.49 0 01-.41.51z'
            style={{
              transformOrigin: '39.9296px 269.988px'
            }}
            id='prefix__elqv4kx31be5f'
            className='prefix__animable'
            fill='#fff'
          />
          <path
            d='M33.72 318.34c-1 .8-3.42-.18-4.36-.6a10.48 10.48 0 01-4.07-3.34 9.74 9.74 0 01-1.82-5.62 22.69 22.69 0 01.21-3.19c.12-1 .23-2.08.35-3.12a7.6 7.6 0 00-.07-3.08c-.6-2-2.65-3.11-4.5-4.07-2.05-1.05-4.24-2.53-4.64-5a8.72 8.72 0 011.29-5.5c.92-1.68 2.09-3.22 2.89-5a6.14 6.14 0 00.59-4 6.56 6.56 0 00-1.23-2.24 33.45 33.45 0 01-3.36-4.3c-.89-1.61-1.16-3.76 0-5.21a5.68 5.68 0 014-1.76 9.74 9.74 0 014.15.72c4.26 1.5 7.26 5.74 11.67 6.71a40.93 40.93 0 005.2.37 6.28 6.28 0 014.6 2.13c2 2.77-.16 7 1.45 10 1.42 2.69 4.37 3.31 6.35 5.61a6.17 6.17 0 011.36 3.6 18 18 0 01-.51 6.56 44.72 44.72 0 01-2.5 6.25c-.09.08-16.91 13.96-17.05 14.08z'
            style={{
              transformOrigin: '34.0547px 290.472px'
            }}
            id='prefix__el2nd7gl3zqlr'
            className='prefix__animable'
            fill='#f19066'
          />
          <g
            style={{
              transformOrigin: '34.0547px 290.472px'
            }}
            className='prefix__animable'
            opacity={0.3}
            id='prefix__el4emzmjbyw2j'
          >
            <path
              d='M33.72 318.34c-1 .8-3.42-.18-4.36-.6a10.48 10.48 0 01-4.07-3.34 9.74 9.74 0 01-1.82-5.62 22.69 22.69 0 01.21-3.19c.12-1 .23-2.08.35-3.12a7.6 7.6 0 00-.07-3.08c-.6-2-2.65-3.11-4.5-4.07-2.05-1.05-4.24-2.53-4.64-5a8.72 8.72 0 011.29-5.5c.92-1.68 2.09-3.22 2.89-5a6.14 6.14 0 00.59-4 6.56 6.56 0 00-1.23-2.24 33.45 33.45 0 01-3.36-4.3c-.89-1.61-1.16-3.76 0-5.21a5.68 5.68 0 014-1.76 9.74 9.74 0 014.15.72c4.26 1.5 7.26 5.74 11.67 6.71a40.93 40.93 0 005.2.37 6.28 6.28 0 014.6 2.13c2 2.77-.16 7 1.45 10 1.42 2.69 4.37 3.31 6.35 5.61a6.17 6.17 0 011.36 3.6 18 18 0 01-.51 6.56 44.72 44.72 0 01-2.5 6.25c-.09.08-16.91 13.96-17.05 14.08z'
              id='prefix__elvxxe2gbtj17'
              className='prefix__animable'
              style={{
                transformOrigin: '34.0547px 290.472px'
              }}
            />
          </g>
          <path
            d='M43.76 310.6a.48.48 0 01-.55-.42C40.74 290.81 28.09 271.1 20.46 266a.49.49 0 11.55-.82c7.78 5.16 20.67 25.19 23.15 44.84a.49.49 0 01-.4.58z'
            style={{
              transformOrigin: '32.2055px 287.851px'
            }}
            id='prefix__ely8w25w215m'
            className='prefix__animable'
            fill='#fff'
          />
          <path
            d='M37 288.36h-.15a66.4 66.4 0 00-14.34.11.5.5 0 01-.51-.47.49.49 0 01.43-.55 66.82 66.82 0 0114.59-.1.49.49 0 01.42.55.48.48 0 01-.44.46z'
            style={{
              transformOrigin: '29.7201px 287.735px'
            }}
            id='prefix__ely2s8ehxnxb9'
            className='prefix__animable'
            fill='#fff'
          />
        </g>
      </g>
      <g
        id='prefix__toy_reads--Flowers--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '250.24px 383.976px',
          animation:
            '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) lightSpeedRight',
          animationDelay: '0s'
        }}
      >
        <g
          id='prefix__toy_reads--flowers--inject-63'
          className='prefix__animable'
          style={{
            transformOrigin: '426.233px 387.027px'
          }}
        >
          <path
            d='M426.34 386.28s-.22-3-2.59-3.34c0 0-1.94-.32-3 2.69 0 0-2.37-1.18-5.6.65a3.49 3.49 0 002.58 3.88s-1.83 1.4-1 4.74c0 0 4.85 1.61 6-1.4 0 0 .43 2.69 5.17 2.69a4.52 4.52 0 000-5.39s3.34-.1 3.88-2.58c.05 0-2.21-3.77-5.44-1.94z'
            style={{
              transformOrigin: '423.447px 389.56px'
            }}
            id='prefix__elzc2r856tgb'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M423.42 386.76a3.3 3.3 0 00-3.58 2.22s0 1.76 3 2 3.48-1.51 3.48-1.51a3 3 0 00-2.9-2.71z'
            style={{
              transformOrigin: '423.08px 388.866px'
            }}
            id='prefix__el9d3r0ht6wz5'
            className='prefix__animable'
            fill='#f0997a'
          />
          <path
            d='M441.36 380.08s-1.06-2.14-2.8-2.39a4.8 4.8 0 00-1.72 3.08 4.41 4.41 0 00-3.81.94 4.05 4.05 0 002.42 2.79 5.42 5.42 0 00.12 4s3.35.24 4.53-2.55c0 0 1.25 1.86 4.66 1.21a3.49 3.49 0 00-1.24-4.06s2.11-1.22 1.86-3.25a5.24 5.24 0 00-4.02.23z'
            style={{
              transformOrigin: '439.215px 383.098px'
            }}
            id='prefix__el7df2a3dm2c8'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M439.45 381.35a2.44 2.44 0 00-2.37 2s.2 1.29 2.44 1.14 2.38-1.49 2.38-1.49a2.2 2.2 0 00-2.45-1.65z'
            style={{
              transformOrigin: '439.49px 382.915px'
            }}
            id='prefix__eloneq4cwnaak'
            className='prefix__animable'
            fill='#f0997a'
          />
          <path
            d='M425.27 376.6s-.66-1.92-1.92-1.26-.82 1.86.28 2.14c0 0-2.36.76-1.7 2s2.68-.05 3.12-.71c0 0 .44 1.93 2.08 1.24s0-2.34 0-2.34 2.52-1 1.15-2.13-3.01 1.06-3.01 1.06z'
            style={{
              transformOrigin: '425.251px 377.68px'
            }}
            id='prefix__elfj80tnd5kfc'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M440 394.94s.2-2-1.22-1.94-1.52 1.36-.63 2.06c0 0-2.47-.27-2.39 1.15s2.47 1.06 3.14.64c0 0-.4 1.94 1.38 2s1-2.13 1-2.13 2.7.14 1.93-1.47-3.21-.31-3.21-.31z'
            style={{
              transformOrigin: '439.553px 395.925px'
            }}
            id='prefix__elf554zf3k03s'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M408.44 393.78s-1.63-2.15-.39-2.67 1.76 1.34 1.76 1.34 1.19-1.45 1.82-.45-.91 2.15-.91 2.15 1.69.92.72 1.83-2.15-.52-2.15-.52-.67 1.63-1.71 1.17-.38-2.33.86-2.85z'
            style={{
              transformOrigin: '409.423px 393.865px'
            }}
            id='prefix__el4k0ekhwqftw'
            className='prefix__animable'
            fill='#fafafa'
          />
        </g>
        <g
          id='prefix__toy_reads--flowers--inject-63'
          className='prefix__animable'
          style={{
            transformOrigin: '72.9775px 382.55px'
          }}
        >
          <path
            d='M85.35 384.49s-1.68-2.53-3.91-2.57a6.13 6.13 0 00-1.68 4.16 5.57 5.57 0 00-4.64 1.79 5.17 5.17 0 003.5 3.13 6.82 6.82 0 00.79 5s4.26-.22 5.3-3.93c0 0 1.86 2.14 6.05.78a4.45 4.45 0 00-2.22-4.92s2.46-1.93 1.83-4.41a6.64 6.64 0 00-5.02.97z'
            style={{
              transformOrigin: '82.9975px 388.96px'
            }}
            id='prefix__elsawdutxirm'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M83.15 386.38a3.1 3.1 0 00-2.66 2.93s.45 1.59 3.25 1 2.76-2.26 2.76-2.26a2.79 2.79 0 00-3.35-1.67z'
            style={{
              transformOrigin: '83.495px 388.359px'
            }}
            id='prefix__elu3t1oi3bse'
            className='prefix__animable'
            fill='#f0997a'
          />
          <path
            d='M63.62 371.88s-.76-2.29-2.46-2.78a4.88 4.88 0 00-2.16 2.84 4.42 4.42 0 00-3.92.41 4.08 4.08 0 002 3.12 5.42 5.42 0 00-.42 4s3.31.71 4.87-1.91c0 0 1 2 4.48 1.84a3.51 3.51 0 00-.68-4.22s2.27-.92 2.31-3a5.28 5.28 0 00-4.02-.3z'
            style={{
              transformOrigin: '61.36px 374.329px'
            }}
            id='prefix__eliio10c59vj'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M61.55 372.87a2.47 2.47 0 00-2.65 1.69s0 1.32 2.27 1.48 2.58-1.16 2.58-1.16a2.22 2.22 0 00-2.2-2.01z'
            style={{
              transformOrigin: '61.325px 374.453px'
            }}
            id='prefix__elg1nn0agso29'
            className='prefix__animable'
            fill='#f0997a'
          />
          <path
            d='M76.71 372.57s-1.14-2.3-2.62-1.27-.73 2.47.69 2.64c0 0-2.84 1.34-1.81 2.82s3.37-.5 3.81-1.39c0 0 .87 2.35 2.82 1.22s-.37-2.94-.37-2.94 3-1.64 1.1-2.87-3.62 1.79-3.62 1.79z'
            style={{
              transformOrigin: '76.8527px 373.889px'
            }}
            id='prefix__el1amwd30if1x'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M65.42 390.23s-2.4-2.44-.92-3.3 2.43 1.4 2.43 1.4 1.32-2 2.22-.83-.8 2.86-.8 2.86 2.28.87 1.2 2.18-2.79-.31-2.79-.31-.58 2.15-2 1.74-.82-2.89.66-3.74z'
            style={{
              transformOrigin: '66.8965px 390.378px'
            }}
            id='prefix__eldz3c7cb4u1c'
            className='prefix__animable'
            fill='#fafafa'
          />
        </g>
      </g>
      <g
        id='prefix__toy_reads--Sun--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '83.9px 64.22px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) fadeIn',
          animationDelay: '0s'
        }}
      >
        <circle
          cx={83.9}
          cy={64.22}
          r={18.39}
          style={{
            transformOrigin: '83.9px 64.22px'
          }}
          className='prefix__animable'
          fill='#f0997a'
          transform='rotate(-22.5)'
          id='prefix__toy_reads--sun--inject-63'
        />
      </g>
      <g
        id='prefix__toy_reads--Clouds--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '405.165px 68.7754px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideUp',
          animationDelay: '0s'
        }}
      >
        <g
          id='prefix__toy_reads--clouds--inject-63'
          className='prefix__animable'
          style={{
            transformOrigin: '405.165px 68.7754px'
          }}
        >
          <path
            d='M362.24 70.07l6.56 3.79a6.58 6.58 0 01-.06-.87v-1.9c0-3.38 2.37-4.75 5.3-3.06a10.32 10.32 0 013.15 3c.48-4.25 3.82-5.83 7.86-3.49A17.5 17.5 0 01393 81.3v2.84a7.61 7.61 0 01-.67 3.29l5.44 3.14a6.52 6.52 0 012.94 5.09c0 1.87-1.32 2.63-2.94 1.7l-35.51-20.5a6.51 6.51 0 01-2.93-5.09c-.02-1.87 1.29-2.63 2.91-1.7z'
            style={{
              transformOrigin: '380.02px 82.1067px'
            }}
            id='prefix__elysksg8f9re'
            className='prefix__animable'
            fill='#ebebeb'
          />
          <path
            d='M405.55 52.67l3.62 1.39v-8c0-6.31 5-7.83 10.5-4.67 5.46 3.16 9.9 10.83 9.9 17.14v1.16a5.29 5.29 0 014.59.71 15.74 15.74 0 017.12 12.33v.53l5 2.91a10.35 10.35 0 014.72 8.16c0 3-2.1 4.2-4.69 2.71L405.55 63.5a10.35 10.35 0 01-4.69-8.12c0-2.99 2.14-4.21 4.69-2.71z'
            style={{
              transformOrigin: '425.93px 63.7366px'
            }}
            id='prefix__elp4xbsan5wql'
            className='prefix__animable'
            fill='#ebebeb'
          />
        </g>
      </g>
      <g
        id='prefix__toy_reads--soccer-ball--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '172.23px 415.37px'
        }}
      >
        <g
          id='prefix__toy_reads--soccer-ball--inject-63'
          className='prefix__animable'
          style={{
            transformOrigin: '172.23px 415.37px'
          }}
        >
          <circle
            cx={172.23}
            cy={415.37}
            r={21.31}
            style={{
              transformOrigin: '172.23px 415.37px'
            }}
            className='prefix__animable'
            fill='#f5f5f5'
            transform='rotate(-58.28)'
            id='prefix__elbv8qq61vp5s'
          />
          <path
            d='M176 405a22.68 22.68 0 00-8.58 2.41 47.08 47.08 0 000 8.61 28.69 28.69 0 007.68 2.65s4.07-3.39 5.19-6.45A17.7 17.7 0 00176 405z'
            style={{
              transformOrigin: '173.756px 411.835px'
            }}
            id='prefix__elbqbqgue0mdi'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M161.69 403.78l-.6-1.19-.12.17-.1.12.57 1.14 5.85 3.52.64.47a.75.75 0 01.07-.12.86.86 0 01.14-.13l-.66-.49z'
            style={{
              transformOrigin: '164.505px 405.3px'
            }}
            id='prefix__elququv5m4is'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M173.85 395.55c-.49-.06-1-.12-1.61-.16a14.48 14.48 0 00-8.82 2.27l-1.19.7a1.15 1.15 0 01.11.31l1.27-.73a14 14 0 018.61-2.22c.75 0 1.41.13 2 .21a1.12 1.12 0 01-.37-.38z'
            style={{
              transformOrigin: '168.225px 397.013px'
            }}
            id='prefix__el5u21sstt114'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M178 398.36l-.28.93-1.93 5.65-.45 1.19.31.1.45-1.18 1.93-5.65.29-.95a1.68 1.68 0 01-.32-.09z'
            style={{
              transformOrigin: '176.83px 402.295px'
            }}
            id='prefix__elucj1137i2q'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M188.29 412.41a6.47 6.47 0 01-.68.5 65.39 65.39 0 00-7.34-.85h-.12a2.28 2.28 0 00-.45 0 1.82 1.82 0 010 .33 2.5 2.5 0 01.55 0 65.59 65.59 0 017.37.85h.06a5 5 0 00.65-.48 2 2 0 01-.04-.35z'
            style={{
              transformOrigin: '184.015px 412.644px'
            }}
            id='prefix__el86mh0ibnxop'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M185.21 400.57a16.55 16.55 0 014.74 6.81 6.1 6.1 0 01.17.78 1.66 1.66 0 01.31-.12 4.55 4.55 0 00-.17-.76 16.76 16.76 0 00-4.82-6.94l-.41-.4a10 10 0 00-.8-.71 1 1 0 01-.07.15.45.45 0 01-.13.11 9.37 9.37 0 01.77.69z'
            style={{
              transformOrigin: '187.23px 403.695px'
            }}
            id='prefix__ele6erdyu9cba'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M175.24 418.67c0-.14.17-.57.39-1.16a1.3 1.3 0 01-.41.13c-.19.54-.3.92-.31 1l2.1 7.93c.05 0 .73.52 1.51 1h.08l.35-.08c-.63-.41-1.26-.84-1.7-1.16z'
            style={{
              transformOrigin: '176.93px 422.54px'
            }}
            id='prefix__elprpzuyx2u3j'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M190 419.74c-.16.4-.33.81-.53 1.23a20.76 20.76 0 01-5.18 6.46c-.3.16-.83.42-1.39.66a1 1 0 01.26.25c.64-.28 1.18-.57 1.31-.64a21.09 21.09 0 005.3-6.59c.18-.39.35-.77.5-1.14a1.49 1.49 0 01-.27-.23z'
            style={{
              transformOrigin: '186.585px 424.04px'
            }}
            id='prefix__elstm0xiufkta'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M168.31 415.31c-.3.16-.63.35-1 .57a46.48 46.48 0 00-6.32 5.06c-.08.14-.35.61-.65 1.23a2.37 2.37 0 01.23.3c.25-.54.5-1 .69-1.34a47.13 47.13 0 016.25-5c.41-.25.77-.45 1.1-.62a1.18 1.18 0 01-.3-.2z'
            style={{
              transformOrigin: '164.475px 418.89px'
            }}
            id='prefix__elr0gc7k6svs'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M173 431.2c-.56 0-1.19-.06-1.9-.11a15.12 15.12 0 01-7.85-2.89 10.62 10.62 0 01-1-.31v.34c.46.16.83.25 1 .28a15.51 15.51 0 008 2.94l1.78.11a.87.87 0 01-.03-.36z'
            style={{
              transformOrigin: '167.64px 429.725px'
            }}
            id='prefix__elj96kjpvqcg'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M154.92 418.35a.74.74 0 01.33 0 8.7 8.7 0 01-.23-1.13 32.33 32.33 0 01.4-8.39 14.92 14.92 0 01.38-1.5h-.35c-.13.46-.25.94-.35 1.43a32.75 32.75 0 00-.41 8.51 8.76 8.76 0 00.23 1.08z'
            style={{
              transformOrigin: '155.19px 412.84px'
            }}
            id='prefix__elhi1yly0xo8l'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M172.23 395.55a16.81 16.81 0 015.67 3.79 20.07 20.07 0 017 .72 12.33 12.33 0 00-.37-2.08 21.11 21.11 0 00-10.53-3.84 17.12 17.12 0 00-1.77 1.41z'
            style={{
              transformOrigin: '178.565px 397.1px'
            }}
            id='prefix__elk8v2wa1m3zj'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M192.9 410.16a17.19 17.19 0 00-2.8-2.83 29.11 29.11 0 01-2.45 5.75 56.72 56.72 0 012 8 19.46 19.46 0 003.41-1 20.91 20.91 0 00.52-4.65 21.8 21.8 0 00-.68-5.27z'
            style={{
              transformOrigin: '190.615px 414.205px'
            }}
            id='prefix__elqkul45uzmf9'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M158.08 429.76s3.43-.3 5.07-1.43c0 0-1.08-3.23-2.05-7.27a41.78 41.78 0 01-6.25-3.84s-1.33 1.38-1.64 5.78a21.48 21.48 0 004.87 6.76z'
            style={{
              transformOrigin: '158.18px 423.49px'
            }}
            id='prefix__elo3ubo40tpks'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M153.57 405.08a8.54 8.54 0 001.69 3.68 14.87 14.87 0 016.3-4.86 44.1 44.1 0 011.95-6.09 19.24 19.24 0 00-3.17-.13 21.45 21.45 0 00-6.77 7.4z'
            style={{
              transformOrigin: '158.54px 403.212px'
            }}
            id='prefix__eliy7kiotzhn'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M184.35 427.57a31.27 31.27 0 01-7.22-1.08 36.9 36.9 0 01-6 4.77s1.52 3.09 3.39 4.22a23.6 23.6 0 007.86-2.26 16 16 0 001.97-5.65z'
            style={{
              transformOrigin: '177.74px 430.985px'
            }}
            id='prefix__el5obroawyaw7'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M190.57 426.21c-3.56 5.06-17.3 8.24-26.07-3.13s-3.7-24.43 2.68-28.41a21.31 21.31 0 1023.39 31.54z'
            style={{
              transformOrigin: '170.74px 415.678px'
            }}
            className='prefix__animable'
            opacity={0.1}
            id='prefix__elsz4sgangge'
          />
        </g>
      </g>
      <g
        id='prefix__toy_reads--character-3--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '394.081px 252.767px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideUp',
          animationDelay: '0s'
        }}
      >
        <g
          id='prefix__toy_reads--Character--inject-63'
          className='prefix__animable'
          style={{
            transformOrigin: '394.081px 252.767px'
          }}
        >
          <path
            d='M421.33 331.77c-.94-3-7.76-23.27-10.35-30.14-2.91-7.77-3.34-10.51-5.18-17.6-.87-3.37-1.82-6.94-2.76-10.52-1.12 5.56-2.76 15.4-3.12 17.58q.06.5.12 1.2c.8 3.34.86 5.71.85 10.19 0 2.83-1.67 14.72-2.59 21.91-.21 1.59-.44 3.19-.57 4.79a8.78 8.78 0 001.17 4.39 6 6 0 01.62 3.71 4 4 0 01-.37 1.14c-.63 1.18-1.93 1.57-3 2.26-1.32.87-2.44 2.52-3.53 3.67a14.34 14.34 0 01-3.76 3.15 16.4 16.4 0 01-5.41 1.19 12.55 12.55 0 01-3.18-.19 8.59 8.59 0 01-2.88-.58 2.29 2.29 0 01-1.35-1.91 3.41 3.41 0 011.51-2.34c4.12-3.57 10.15-8.05 11.18-13.69 0 0-.68-4.54-1-7.26-.94-9.26-1.39-16.72-2.29-24-1.08-8.85-1.41-9.87-1.35-17.43 0-2.57.1-5.3.23-8.11 0-8.85.47-17.44.35-24 0 0 12.25.06 22 .54 10.47 1.13 14.08 3.44 13.79 4.78a93.14 93.14 0 011.76 14.24c.38 6.38 1.7 23 1.7 23a34.23 34.23 0 014.7 13.85c.34 2.81 1.73 21.77 2 23.14a14 14 0 001.78 3.56 5.84 5.84 0 011 2.71 3.14 3.14 0 01-.12 1.05c-.35 1.2-1.44 2-2.29 2.92a11.27 11.27 0 00-2.7 5.87 9.46 9.46 0 01-7.88 7.37 9.74 9.74 0 01-6.49-1.38c-.79-.5-1.58-1.3-1.4-2.21a3 3 0 011.22-1.52 20.83 20.83 0 007.57-13 5.44 5.44 0 00-.06-2.25'
            style={{
              transformOrigin: '404.722px 300.741px'
            }}
            id='prefix__eluvt70i2io6'
            className='prefix__animable'
            fill='#b16668'
          />
          <path
            d='M399.45 338.81s.32 0 .29.82a1.88 1.88 0 01-1 1.82c-.69.52-3.16 1.57-4.69 3.64-1.08 1.47-1.53 3.05-5.16 5s-8.88 1.28-10.72.39-2.24-1.27-2.36-2.9z'
            style={{
              transformOrigin: '387.778px 345.081px'
            }}
            id='prefix__el6zfom5j3oje'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M388.76 330c.47.69 2.94 1 4 1.4s1 2.86 1 2.86c1 0 1.06-.75 1.37-2 .53-2.11 2.1-2.13 2.6-2.41v-.64s.53-.17.67.84a22.92 22.92 0 001 4c.32.9.86 4.19.17 5.2a14.71 14.71 0 01-2.15 1.65 15 15 0 00-4.33 3.89c-.88 1.36-3.57 4.51-9.59 4.66s-7.72-2-7.72-2a3.27 3.27 0 011-4.34c2.46-1.88 3.5-2.69 6.54-5.17 4.91-4.13 3.53-8.38 5.44-7.94z'
            style={{
              transformOrigin: '387.624px 339.328px'
            }}
            id='prefix__el7usflqeog3'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M389.68 347.83c-1.23-2.94-2.85-5-6.06-6.14s-4.66-.72-6 .08-3.58 2.82-1.92 5.64c0 0 .93 2.3 6.72 2.35s7.26-1.93 7.26-1.93z'
            style={{
              transformOrigin: '382.396px 345.376px'
            }}
            id='prefix__el1mbinpuej1l'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M434.35 336.71c.28 1 .58 1.82-.34 3s-2.11 1.81-2.92 2.71-1.1 2.84-1.59 4.5a10.55 10.55 0 01-4.4 5.91c-2.5 1.42-4.5 2.09-7.41 1.6s-4.5-1.36-5.25-3.84z'
            style={{
              transformOrigin: '423.535px 345.65px'
            }}
            id='prefix__el976iztgjxxt'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M420.77 331.4c-.47-1.2 2.55.58 5.88-.27.6-.16.89.11 1 .72s.3 1.27 1.05 1a1 1 0 00.61-.68 4.25 4.25 0 00.18-.93c.12-.86.31-2.13 1.13-2.57l-.17-1s.77-.72 1.3 1.71 3.1 4 2.56 7.34a10.49 10.49 0 01-2.3 2.91 8.57 8.57 0 00-3.15 5.17c-.5 2.48-2.76 6.29-6.11 7.32a11.41 11.41 0 01-10.56-1.79 3.18 3.18 0 01.88-4.51 26 26 0 005.17-5.35c1.53-1.9 3.02-6.32 2.53-9.07z'
            style={{
              transformOrigin: '422.996px 340.136px'
            }}
            id='prefix__eli8v94djk2p'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M426.24 349.8c-1.57-2.32-3.53-5-7.22-5.72s-5.16.9-6.36 2a2.88 2.88 0 00-.43 4.28 9.32 9.32 0 007.66 2.64 9.13 9.13 0 006.35-3.2z'
            style={{
              transformOrigin: '418.855px 348.483px'
            }}
            id='prefix__el7hx0c5a0g2i'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M420.87 248.27s.94 9.49 1.31 15.06 2.38 28 2.38 28a32.83 32.83 0 014.44 13.26c.83 7.33 1.18 14.94 1.38 17.25 0 0-5.66 3.85-11.73 2.6-.46-1.69-8.78-24.16-10.64-29.95-1.8-5.6-4.86-18.9-4.86-18.9s-2.38 12.6-2 15c1.71 9.52-1.64 25.61-2.26 32.22 0 0-.7 1.89-5.44 2-4.9.14-5.83-1.52-5.83-1.52s-3.44-31.67-3.9-37.64.4-28.69 1-36.5z'
            style={{
              transformOrigin: '406.985px 286.544px'
            }}
            id='prefix__els6qccyacnzh'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M403.1 275.59l-1.1-5.94s-8-.46-11.55-4.35c0 0 1.29 5.19 9.63 5.81l1.2 5.73-.13 11.61c.52-4.35 1.24-8.14 1.95-12.86z'
            style={{
              transformOrigin: '396.775px 276.875px'
            }}
            id='prefix__eljzqsrdwaha9'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M365.67 251.52c-3 1.51-6.07 2.95-9.2 4.21a23.15 23.15 0 01-5.55 1.65 9.61 9.61 0 01-5.65-.79 5.32 5.32 0 01-2.31-2.17c-.49-.95-1.24-3-.72-4 .69-1.27 2-1.32 3.45-1.53 1.18-.18 3-.46 4.17-.68.91-.18 1.63-.45 1.42-.6l-1.21-.86-2.16-1.57a.88.88 0 01.11-.94c.4-.37 1.27 0 1.62.1a17.12 17.12 0 004.8.27 11.52 11.52 0 004.23-.43c.93-.41 13.2-7.8 16-10.31 0 0 3.67-14.87 6.23-22 2.35-6.57 7.32-9.18 11.7-8.38a30.65 30.65 0 01-.78 12.82c-2.76 10.19-4.75 20.61-6.19 23.14a10 10 0 01-3.62 3.42c-4 2.42-8.2 4.46-12.34 6.6z'
            style={{
              transformOrigin: '367.505px 230.428px'
            }}
            id='prefix__elba06vxk6i28'
            className='prefix__animable'
            fill='#b16668'
          />
          <path
            d='M394 203.34a12 12 0 00-7.21.72c-2.78 1.18-4.54 2.55-6.33 8.05-1.46 4.35-3.46 11.75-3.46 11.75s3.31 3.35 12.19 3.94c0 0 4-14.61 4.17-14.69s.64-9.77.64-9.77z'
            style={{
              transformOrigin: '385.5px 215.442px'
            }}
            id='prefix__el6eudtkm3cyh'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M418.28 205.37c3.86.79 5 2.65 5.48 6.12a25.44 25.44 0 01-.88 9.4l-1.53 16.87.8 18.24c-8.3 4.69-26.78 7.5-38.17-.65 0 0-.24-33.19.55-38.89 1.38-9.9 5.45-13.42 10.2-13.1l13.74.92a63.32 63.32 0 019.81 1.09z'
            style={{
              transformOrigin: '403.908px 231.932px'
            }}
            id='prefix__eldrbd1eqwitg'
            className='prefix__animable'
            fill='#f19066'
          />
          <g
            style={{
              transformOrigin: '403.908px 231.932px'
            }}
            className='prefix__animable'
            opacity={0.5}
            id='prefix__el0s292semz6ok'
          >
            <path
              d='M418.28 205.37c3.86.79 5 2.65 5.48 6.12a25.44 25.44 0 01-.88 9.4l-1.53 16.87.8 18.24c-8.3 4.69-26.78 7.5-38.17-.65 0 0-.24-33.19.55-38.89 1.38-9.9 5.45-13.42 10.2-13.1l13.74.92a63.32 63.32 0 019.81 1.09z'
              style={{
                transformOrigin: '403.908px 231.932px'
              }}
              id='prefix__eli5hbshkcgs'
              className='prefix__animable'
              fill='#fff'
            />
          </g>
          <path
            d='M386.07 177.79a10.15 10.15 0 1010.44-10.21 10.33 10.33 0 00-10.44 10.21z'
            style={{
              transformOrigin: '396.22px 177.728px'
            }}
            id='prefix__el2gxxq5k2jz5'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M416.8 186.48s-2 8.18-2.73 9.51a3.7 3.7 0 01-2.2 1.68l.29-5.79z'
            style={{
              transformOrigin: '414.335px 192.075px'
            }}
            id='prefix__elws9yc6xxie'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M414.94 174.63a4.06 4.06 0 013.32 1.27c1.4 1.52.46 6.11-1.46 10.58l-3.31.29z'
            style={{
              transformOrigin: '416.21px 180.692px'
            }}
            id='prefix__elkghy1k9r48'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M412.5 186.74c.65.41 1.63-.83 2.47-1.66s3.54-1.92 4.79.92-1.36 6.59-3.48 7.19c-3.65 1-4.12-1.31-4.12-1.31l-.65 13a11.62 11.62 0 01-9 6.41c-6.44.69-6.22-4.68-4-7.21l.11-3.66a15.51 15.51 0 01-4.73-.61c-2-.61-3.55-1.88-4.8-5-1.68-4.23-1.79-8.8-.22-17.32 1.82-9.87 14-9.82 20.18-5.7s3.45 14.95 3.45 14.95z'
            style={{
              transformOrigin: '403.922px 190.262px'
            }}
            id='prefix__elhzeoqnws6mw'
            className='prefix__animable'
            fill='#b16668'
          />
          <path
            d='M410.51 180.08c.5 2.87.84 7.72 2 7.77.33 0 1.66-2 2.5-2.77.38-.32.4-1.05.45-1.5a35.71 35.71 0 00.11-5.17c-.09-1.84.26-3.88-.27-5.66a6 6 0 00-2.6-3.27c-2.7-1.73-6.32-1.65-9.38-2.35a31.14 31.14 0 01-10.13-4l-.94-.6a1.55 1.55 0 00-.93-.31.94.94 0 00-.66.49 2.23 2.23 0 00-.27.8 8.65 8.65 0 00-.06 2.59 4.44 4.44 0 00.52 2.2l-2.19-1.46a.79.79 0 00-.5-.18c-.3 0-.43.42-.47.72a7.75 7.75 0 003.15 6.73 13.92 13.92 0 007.22 2.57c2.57.27 5.17.11 7.76.14a6.69 6.69 0 013.27.49 4 4 0 011.42 2.77z'
            style={{
              transformOrigin: '401.646px 175.035px'
            }}
            id='prefix__elh4mt2hegtm9'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M414.91 175.36l4.06-2a2.2 2.2 0 00-3-1.08 2.38 2.38 0 00-1.06 3.08z'
            style={{
              transformOrigin: '416.836px 173.703px'
            }}
            id='prefix__eljie9f77j7sd'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M398.62 200.45S405 200 408 198.2a8.2 8.2 0 003-2.76 8 8 0 01-1.73 3.3c-2.95 3.3-10.62 3.73-10.62 3.73z'
            style={{
              transformOrigin: '404.81px 198.955px'
            }}
            id='prefix__elabxv7zc9p3'
            className='prefix__animable'
            fill='#9a4a4d'
          />
          <path
            d='M401.31 185a1.34 1.34 0 101.38-1.36 1.37 1.37 0 00-1.38 1.36z'
            style={{
              transformOrigin: '402.65px 184.98px'
            }}
            id='prefix__elaffxi3kdndn'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M403.17 181l2.8 1.65a1.71 1.71 0 00-.6-2.29 1.59 1.59 0 00-2.2.64z'
            style={{
              transformOrigin: '404.678px 181.399px'
            }}
            id='prefix__el1zdwk2q9umg'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M401.86 192.18l-5.32 1.82a2.76 2.76 0 003.53 1.84 2.94 2.94 0 001.79-3.66z'
            style={{
              transformOrigin: '399.27px 194.082px'
            }}
            id='prefix__ellv352ykpwl'
            className='prefix__animable'
            fill='#9a4a4d'
          />
          <path
            d='M400.54 193.72a2.21 2.21 0 011.26.45 2.85 2.85 0 01-1.73 1.68 2.67 2.67 0 01-1.81 0 2.23 2.23 0 012.28-2.13z'
            style={{
              transformOrigin: '400.03px 194.864px'
            }}
            id='prefix__elgidcs80dsv4'
            className='prefix__animable'
            fill='#f28f8f'
          />
          <path
            d='M390 181.33l2.65-1.92a1.57 1.57 0 00-2.25-.41 1.71 1.71 0 00-.4 2.33z'
            style={{
              transformOrigin: '391.179px 180.012px'
            }}
            id='prefix__eldkgu49e1qm'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M390.55 184a1.34 1.34 0 101.38-1.36 1.37 1.37 0 00-1.38 1.36z'
            style={{
              transformOrigin: '391.89px 183.98px'
            }}
            id='prefix__el7q7viaqhzmo'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            style={{
              transformOrigin: '395.535px 186.94px'
            }}
            id='prefix__el6wpaezqg977'
            className='prefix__animable'
            fill='#9a4a4d'
            d='M397.68 183.37l-.59 7.14-3.7-1.38 4.29-5.76z'
          />
          <path
            d='M433.66 211.79c1.16-.9 1.59-3.55.81-6.19s-4.36-11.51-7.06-9.59-2 7.61-.52 11.25 5.59 5.43 6.77 4.53z'
            style={{
              transformOrigin: '430.223px 203.88px'
            }}
            id='prefix__els3ejqxotbnf'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M443.19 170.91c-.59-3.33-1.25-6.64-2.1-9.91a23.53 23.53 0 00-2-5.43 9.52 9.52 0 00-4-4.06A5.14 5.14 0 00432 151c-1.06.17-3.19.82-3.63 1.8-.6 1.31.15 2.41.83 3.68a34.59 34.59 0 011.8 3.79c.09.23 1 2.1.32 1.88a1.17 1.17 0 01-.4-.28 3.18 3.18 0 00-2.86-.75.71.71 0 00-.51.3c-.17.32.1.68.35.94A33.83 33.83 0 01431 166a11.7 11.7 0 012.18 3.66c.22 1 1.23 16 .89 19.73 0 0-8.21 8.54-13.6 13.67a9.22 9.22 0 00-3.11 6.35c-1.07 9.46 3.91 14.21 6.06 11.41 0 0 19.95-21.93 22-26.67a10 10 0 00.59-4.94c-.43-4.64-1.3-9.25-2.06-13.85-.25-1.48-.5-2.97-.76-4.45z'
            style={{
              transformOrigin: '431.654px 186.282px'
            }}
            id='prefix__elwyd15takk7'
            className='prefix__animable'
            fill='#b16668'
          />
          <path
            d='M416.16 204.89c1.09-1.57 9.35-7.17 11.25-8.88-.88 3.62 2.53 14.3 6.25 15.78 0 0-10 10.24-11.08 11s-4-.09-5.61-4.91-1.97-11.3-.81-12.99z'
            style={{
              transformOrigin: '424.57px 209.522px'
            }}
            id='prefix__el1ccdak864gn'
            className='prefix__animable'
            fill='#455a64'
          />
        </g>
      </g>
      <g
        id='prefix__toy_reads--character-2--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '255.539px 212.765px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideRight',
          animationDelay: '0s'
        }}
      >
        <g
          id='prefix__toy_reads--character--inject-63'
          className='prefix__animable'
          style={{
            transformOrigin: '255.539px 212.765px'
          }}
        >
          <path
            d='M239.72 201.83c-2.74 8.51-3.59 10.85-5.66 15.87-1.55 3.74-5.28 17.61-7.66 28.12-.51 2.25 1.08 6.21 1.91 8.4 4.85 12.78 12.41 33.51 12.41 33.51a19.19 19.19 0 01.86 4.43 24.09 24.09 0 01-2.31 13.56 5.88 5.88 0 00-.58 1.43 1.74 1.74 0 00.29 1.46 2 2 0 001.23.58 4.81 4.81 0 003-.65 8.54 8.54 0 002.29-2c2.23-2.77 3.23-6.3 4.18-9.73.57-2 1.36-4.47.89-6.59a26.66 26.66 0 01-.42-3.35s-1.75-9.47-2.89-20.28c-1-9.62.29-10.41-4.29-17.57l19.92-37.86z'
            style={{
              transformOrigin: '244.596px 255.525px'
            }}
            id='prefix__el9oxgyynbxtm'
            className='prefix__animable'
            fill='#ffbda7'
          />
          <path
            d='M254.14 291.06a3 3 0 011.08 1.58c.06 2.28-2.23 4.47-2.95 6.09a17.86 17.86 0 00-1.08 4.85 18.13 18.13 0 01-.61 3.52 6.17 6.17 0 01-2.41 2.84 13.7 13.7 0 01-5.57 2.33 10.34 10.34 0 01-5.2-.24c-.56-.25-.88-1.34-.85-1.91s1.2-.46 1.81-.82c1.27-.74 2.58-1.43 3.8-2.24a13.35 13.35 0 002.76-2.29 17.59 17.59 0 001.83-2.73q1.73-2.87 3.51-5.7c.23-.34 3.4-5.54 3.88-5.28z'
            style={{
              transformOrigin: '245.885px 301.772px'
            }}
            id='prefix__elsp8ttp9k8ym'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M242.17 290.81l2.25-.14a13.57 13.57 0 002.12-.16 5.16 5.16 0 002.92-1.95 2.72 2.72 0 00.52-2.42c.62-.17 1 .21 1.35.77s.71 1.13 1.08 1.7a5.8 5.8 0 001 1 2.92 2.92 0 011 2.62c-.37 1.86-1.91 3.94-2.88 5.59a8.51 8.51 0 00-.8 2.74c-.35 1.56-.4 3.15-.76 4.71a8 8 0 01-4.26 4.73 16.52 16.52 0 01-6 1.41 5 5 0 01-2.67-.51 2.67 2.67 0 01-1.13-2 5 5 0 011.14-3.14c.67-.92 1.47-1.75 2-2.72a13.24 13.24 0 001.44-5.73 9.09 9.09 0 00-.22-3.75A2.72 2.72 0 01240 292a1.47 1.47 0 01.9-.92 4.44 4.44 0 011.27-.27z'
            style={{
              transformOrigin: '245.174px 298.764px'
            }}
            id='prefix__elobw3jmpnmjg'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M249.66 306.08c-1.27-2.89-4.84-3.29-7.93-2.88a12.19 12.19 0 00-3.67 1.17c-.36.45-.73.89-1.07 1.36a5 5 0 00-1.14 3.14 2.67 2.67 0 001.13 2 5 5 0 002.67.51 16.52 16.52 0 006-1.41 8.58 8.58 0 004.01-3.89z'
            style={{
              transformOrigin: '242.755px 307.232px'
            }}
            id='prefix__elhzgs1kpda0d'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M229.72 228.41c3.66-11.87 6.48-23.3 8.18-26.58l25 7.67-19.47 39.5c1.58 1.5 3 5.47 3.51 11.68s2.42 20.95 2.42 20.95a8 8 0 01-4.63 2.83 8.79 8.79 0 01-5.55-.07S230 262.19 228 256.18c-2.57-7.71-2.6-9.43-1.73-14.49a107.48 107.48 0 013.45-13.28z'
            style={{
              transformOrigin: '244.328px 243.353px'
            }}
            id='prefix__elq36ubossa0q'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M246.17 220.38a13.28 13.28 0 01-6.62-8.41s3.36 5.67 8.74 7.59l.83 17.92-5.69 11.52 4.16-12.8z'
            style={{
              transformOrigin: '244.335px 230.485px'
            }}
            id='prefix__elfgwt6ngamhj'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M282.94 219.41a4.83 4.83 0 012.36-1.77 6.07 6.07 0 015.52 1.84c1.19 1.39 1.59 3.15 2.27 4.79s2.52 2.61 4 3.59a12.43 12.43 0 014.16 4.28c1.55 2.88 1.33 7.42-2.15 8.66z'
            style={{
              transformOrigin: '292.554px 229.172px'
            }}
            id='prefix__elvdkxqxqc1rh'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M283.75 218.7a6.31 6.31 0 015.65 1.55c1.48 1.35 2.4 3.63 3.3 5.26s2.84 2.58 4.35 3.61a8.45 8.45 0 012.72 2.7 9.67 9.67 0 011.32 3.59 6 6 0 01-.23 2.88 4.58 4.58 0 01-1 1.88 4.06 4.06 0 01-2.92 1.16 3.73 3.73 0 01-2.43-1 11.72 11.72 0 01-1.41-1.94c-1.63-2.38-4.43-3.92-7-5.16-1.21-.59-2.62-1-3.52-2.08s-.79-2.42-1-3.66-.35-3.43-1.46-4.35c-.37-.31-.14-.79.24-1.23s1.95-2.71 3.39-3.21z'
            style={{
              transformOrigin: '290.551px 229.943px'
            }}
            id='prefix__elt69iweqj5ws'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M297.37 230.5c-3.41.15-5.37 3.82-5.62 6.28a10.43 10.43 0 011.38 1.59 11.72 11.72 0 001.41 1.94 3.73 3.73 0 002.43 1 4.06 4.06 0 002.92-1.16 4.58 4.58 0 001-1.88 6 6 0 00.23-2.88 9.67 9.67 0 00-1.32-3.59 7.41 7.41 0 00-1-1.26 7.4 7.4 0 00-1.43-.04z'
            style={{
              transformOrigin: '296.475px 235.897px'
            }}
            id='prefix__elludm6h8y84'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M260.48 258.39c3.14.4 5.25-.44 10.28-6.6s15.77-22.58 15.77-22.58a5 5 0 00-.91-5.59c-2.76-2.73-4.72-1.46-4.72-1.46s-2.63 2.59-5.56 5.09c-2.7 2.3-6.09 4.49-8.44 6.66-1.64 1.52-6.42 24.48-6.42 24.48z'
            style={{
              transformOrigin: '273.744px 240.17px'
            }}
            id='prefix__elpr8nju2nb8a'
            className='prefix__animable'
            fill='#ffbda7'
          />
          <path
            d='M271.15 230.5a146.61 146.61 0 001.16-16.5 36.46 36.46 0 00-3-13.41l-30.83 3.56c-.24 8.7 9.82 15.37 9.82 15.37l.53 14.72c1.76 20 4.57 23.29 10.73 24.57s10.09-5.22 12.88-8.48 9.14-13.7 9.14-13.7a7.91 7.91 0 00-2.85-5.47 11.71 11.71 0 00-5.19-2.49z'
            style={{
              transformOrigin: '260.028px 229.782px'
            }}
            id='prefix__elacihlcwe5d6'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M271.15 230.5s-1.35 12.54-2.48 18a54.61 54.61 0 002.94-8.38c1.67-7.1 1.11-10.77 1.11-10.77z'
            style={{
              transformOrigin: '270.74px 238.925px'
            }}
            id='prefix__elbm15l0pwqc'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M259.76 161.23l-5.86-.18a16.87 16.87 0 01-8-.8c-1.9-.69-4.82-1.86-5.56-3.84-2-5.28-1.76-11.7-.34-17.07a30.87 30.87 0 013.69-8.47c1.49-2.39 5.79-2.85 8.42-2.86 9 0 17 7.24 17 16.25v2.22c-.05 9.52-7.11 10.52-9.35 14.75z'
            style={{
              transformOrigin: '253.999px 144.62px'
            }}
            id='prefix__el0ajt1h9goyvd'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M258.18 160.76a4 4 0 01-.52-.92 2.53 2.53 0 01.36-2.79 17.31 17.31 0 012.3-2.54 6.63 6.63 0 012.2-1.43c3.67-1.3 6.23 3.53 5.55 6.6a3.6 3.6 0 014.73 2.87 4.42 4.42 0 014.95 1.69 4.66 4.66 0 015.45.47s3.12-3 5.88-1.12a5.1 5.1 0 012.48-2c1.6-.54 4.05-.05 4.81 1.46a2.09 2.09 0 00-2.59.49c-.94 1.32-2 2.6-4.08 1.57a3.52 3.52 0 01-1.69 1.93 5.12 5.12 0 01-2.13.67 4.07 4.07 0 01-2.15-.4 4.07 4.07 0 01-6.19 1.1 5.64 5.64 0 01-3.57 1.14 2.82 2.82 0 01-2.63-2.33 3.26 3.26 0 01-1.6.27 5.49 5.49 0 01-1.66-.22 4.76 4.76 0 01-2.61-1.85 4.45 4.45 0 01-.78-2.42 18.19 18.19 0 01-2.39-.09 7.55 7.55 0 01-2.75-.94 4.77 4.77 0 01-1.37-1.21z'
            style={{
              transformOrigin: '276.883px 161.208px'
            }}
            id='prefix__elea94jgdck7i'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M210 139.16c-.45-3.14-.82-6.3-1-9.47a22 22 0 01.18-5.44 9 9 0 012.4-4.81 5 5 0 012.63-1.41 3.19 3.19 0 012.78.88 4.51 4.51 0 01.69 3.87 22.86 22.86 0 00-.5 3.3c0 .23-.28 2.16.26 1.78a1.08 1.08 0 00.28-.38A3 3 0 01220 126a.68.68 0 01.54.12c.25.23.11.64 0 .94a32.26 32.26 0 00-1.68 4.2 10.78 10.78 0 00-.88 3.91c.09.95 3.55 14.64 5 17.92 0 0 13.95 9.75 21.67 12.34 1 6.22-2.33 11.42-7.26 12.33 0 0-19.69-12.18-22.92-16.87a12.84 12.84 0 01-1.62-4.51c-1-4.28-1.53-8.65-2.2-13-.29-1.38-.5-2.81-.65-4.22z'
            style={{
              transformOrigin: '226.868px 147.873px'
            }}
            id='prefix__elnjk6gcel4fo'
            className='prefix__animable'
            fill='#ffbda7'
          />
          <path
            d='M264.07 166.89c3.14.34 4.1 2.06 5 5.08a25.27 25.27 0 011 5.36l-.07 17.52 1.78 11.66c-4.94 3.91-25 8.08-36.37.88.23-6.06-.8-21.95 0-28.84.81-7.09 2.44-10.23 5.24-12.08 1.38-.91 5.3-1.28 6.82-1.27l11.07.82z'
            style={{
              transformOrigin: '253.44px 188.258px'
            }}
            id='prefix__el7yv1pkuosnx'
            className='prefix__animable'
            fill='#f19066'
          />
          <path
            d='M286.46 156.72c-6.78 13.79-11.84 17.66-16.46 26.28-7.69.36-8.55-11.1-5-16 0 0 11.51-11.5 13.4-17.24 1.75-5.28 4.27-14.51 4.13-18.93a3 3 0 00-.85-2c-1.46-1.57-1.46-2.83-2.39-4.56-.73-1.35-1.53-1.85-1.3-2.15a1.74 1.74 0 012.36-.18 9 9 0 011.79 1.72 19.74 19.74 0 00-1.39-6c-1.61-3.65-1.22-5.51 3.7-4.21 3.08.82 5.77 3.23 6.23 10.64a76.35 76.35 0 01-.05 9.42c-.53 5.7-1.23 10.92-1.94 15.65a31.24 31.24 0 01-2.23 7.56z'
            style={{
              transformOrigin: '276.897px 148.022px'
            }}
            id='prefix__ely2fxm0jpr7j'
            className='prefix__animable'
            fill='#ffbda7'
          />
          <path
            d='M260.19 149.3c.62.38.77.57 1.2-.46.92-2.2 4.38-3 5.61-.38s-1.15 6.22-3.13 6.83c-3.41 1-3.9-1.15-3.9-1.15l-.36 12.27a11 11 0 01-8.31 6.2c-6 .77-5.94-4.28-3.94-6.7v-3.45a14.82 14.82 0 01-4.47-.47c-1.91-.54-3.36-1.71-4.6-4.63-1.67-4-1.86-8.25-.55-16.29 1.53-9.32 13-9.5 18.87-5.75s3.58 13.98 3.58 13.98z'
            style={{
              transformOrigin: '252.109px 152.861px'
            }}
            id='prefix__el3vbrtl5q1j4'
            className='prefix__animable'
            fill='#ffbda7'
          />
          <path
            d='M261.39 148.84l-.08 2.76a2.63 2.63 0 01-2.61-2.6l-.11-5.77 3.78.71z'
            style={{
              transformOrigin: '260.48px 147.415px'
            }}
            id='prefix__eldtf2st3hnw4'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M248.37 128.47a15.13 15.13 0 00-15.6 14.63l-.06 2 7.39.23a6.88 6.88 0 007-5.73l-1 5.71a6 6 0 006.17-4.2l.33-1.41a6.86 6.86 0 006.59 6.25h1.78v-10.58z'
            style={{
              transformOrigin: '246.84px 137.206px'
            }}
            id='prefix__elrcl9cmeh0uj'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M247.4 162.46s6-.57 8.74-2.3a7.64 7.64 0 002.72-2.65 7.64 7.64 0 01-1.56 3.14c-2.71 3.16-9.92 3.28-9.92 3.28z'
            style={{
              transformOrigin: '253.12px 160.72px'
            }}
            id='prefix__elast6bj6o6w'
            className='prefix__animable'
            fill='#f0997a'
          />
          <path
            d='M248.59 148.92a1.26 1.26 0 102.52-.08 1.28 1.28 0 00-1.3-1.27 1.29 1.29 0 00-1.22 1.35z'
            style={{
              transformOrigin: '249.85px 148.855px'
            }}
            id='prefix__elq34ke3dfws'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M249.85 155.06l-4.3 1.76a2.28 2.28 0 003 1.35 2.42 2.42 0 001.3-3.11z'
            style={{
              transformOrigin: '247.784px 156.698px'
            }}
            id='prefix__elc5sto8g4rj8'
            className='prefix__animable'
            fill='#f0997a'
          />
          <path
            d='M237.4 144.82l2.86-1.14a1.47 1.47 0 00-1.95-.89 1.61 1.61 0 00-.91 2.03z'
            style={{
              transformOrigin: '238.784px 143.75px'
            }}
            id='prefix__elwkfjzf2l648'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M238.7 148.88a1.27 1.27 0 001.3 1.26 1.31 1.31 0 10-1.3-1.26z'
            style={{
              transformOrigin: '240.009px 148.83px'
            }}
            id='prefix__elzd7mcixbh5o'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            style={{
              transformOrigin: '243.42px 150.51px'
            }}
            id='prefix__el7bdq829i5no'
            className='prefix__animable'
            fill='#f0997a'
            d='M245.28 147.14l-.17 6.74-3.55-1.09 3.72-5.65z'
          />
        </g>
      </g>
      <g
        id='prefix__toy_reads--character-1--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '119.709px 247.189px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomIn',
          animationDelay: '0s'
        }}
      >
        <g
          id='prefix__toy_reads--character--inject-63'
          className='prefix__animable'
          style={{
            transformOrigin: '119.709px 247.189px'
          }}
        >
          <path
            d='M114.34 330.36a4.15 4.15 0 01-.41-1.15 6.14 6.14 0 01.55-3.79 9.62 9.62 0 00.92-4.55c-.08-1.64-.28-3.07-.53-4.84-1-7.32-3.1-19.39-3.18-22.28-.12-4.91-.12-7.34.79-11.2 0 0-3.41-11.15-4.85-17.52-2.3-10.16-3.86-18-3-26.85.73-8-.14-11.33 4.51-16.67.53-.69.87-1.11.87-1.11s16.13 17.36 17 20.36c.56 9.86.92 21.67 1.22 30.95.26 7.71-.06 8.76-.95 17.81-.73 7.47-1.49 15.72-2.38 24.48-.28 2.79-.5 6.11.38 8.81 1.81 5.55 6.9 8.85 11.2 12.39a3.48 3.48 0 011.59 2.34 2.34 2.34 0 01-1.33 2 8.81 8.81 0 01-2.92.66 12.57 12.57 0 01-3.24.27 16.89 16.89 0 01-5.55-1.08c-1.51-.72-2.63-2.46-3.81-3.65a20 20 0 00-3.41-3.23c-1.08-.68-2.81-.95-3.47-2.15z'
            style={{
              transformOrigin: '121.23px 280.442px'
            }}
            id='prefix__elbbo2tqnutk5'
            className='prefix__animable'
            fill='#ffa8a7'
          />
          <path
            d='M138.42 338.26a3.22 3.22 0 01-.35 2.08c-.54.67-2.52 2.45-7.64 2.24-4.91-.2-7.78-2.56-8.79-3.88s-1.93-2.6-2.93-3.67a8.43 8.43 0 00-3.37-1.85c-1-.31-1.24-.66-1.15-2.06.31-1.31 24.23 7.14 24.23 7.14z'
            style={{
              transformOrigin: '126.319px 336.79px'
            }}
            id='prefix__eliukza3sf9i'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M115.41 321.24v.87s-.08 2 1.23 2.46c1.54.55 1.44-.59 2.61-1.51 2.54-2 5.64-1.86 6.38-.14a35.49 35.49 0 005.7 7.66 26.78 26.78 0 003.24 2.65c1.23.88 4.23 2.47 3.94 4.41s-2.12 3-4.59 3.41a14.62 14.62 0 01-9.67-1.92c-2.27-1.47-4.24-4.33-5.09-5.17-1.54-1.52-4-1.83-4.8-2.61a6.29 6.29 0 01-.55-4.41c.31-1.73.76-3.27.82-4.14s.16-1.49.78-1.56z'
            style={{
              transformOrigin: '126.082px 331.225px'
            }}
            id='prefix__ely2u5by6ajdj'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M130.73 333.09a6.85 6.85 0 013.31-.22 1.77 1.77 0 01.89.36c1.23.88 4 2.41 3.67 4.35s-2.06 3.12-4.53 3.53c-2.27.38-6.25.35-9.07-1.54a7.85 7.85 0 015.73-6.48z'
            style={{
              transformOrigin: '131.814px 337.032px'
            }}
            id='prefix__elfkkrmr9v5y'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M68.2 323.2a5.74 5.74 0 011.68-2.44 30.81 30.81 0 002.45-2.25 10.29 10.29 0 001.41-2.81c.25-.6.86-2.61 1.64-5.29 2.06-7.1 5.35-18.9 6.5-21.55 2-4.5 3.86-7.41 6.32-9.45 0 0 .58-12.29.8-18.82.34-9.63 1.46-20 6.1-26.74s8.4-8.71 14.86-11.59l1.26-.64s2.16 30.4.83 29.08c-1.72 7.85-4 17.07-5.88 25.14-1.7 7.28-2.16 8.62-7.21 16.31-4.11 6.26-9 13.73-13.52 21.31-2 3.29-4.13 7.72-4.58 11.61a21.36 21.36 0 003.92 14.85 3 3 0 01.85 1.79c0 1-1 1.56-1.92 1.86a9.85 9.85 0 01-6.77-.19 9.62 9.62 0 01-6-9.2 11.6 11.6 0 00-1.26-6.47c-.64-1.11-1.52-2.17-1.58-3.45a2.92 2.92 0 01.1-1.06z'
            style={{
              transformOrigin: '90.2856px 282.849px'
            }}
            id='prefix__elo839zxrqh6'
            className='prefix__animable'
            fill='#ffa8a7'
          />
          <path
            d='M85.59 343.75a3.09 3.09 0 01-2.41 2.25 11.31 11.31 0 01-9-1.94c-3.87-2.78-4.39-5.08-4.56-7.17s.43-5.07-.29-6.64-1.66-2.6-1.92-3.79a6.63 6.63 0 01.3-2.86z'
            style={{
              transformOrigin: '76.4785px 334.921px'
            }}
            id='prefix__eltc8h5680h5'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M72.33 318.51s-.41.71-.49.92a2.94 2.94 0 00.84 3.75c.59.52 1.9 0 3-.12a6.28 6.28 0 015.41 2.2c-.11 3.52-.2 5.62.79 8.47a32 32 0 002.38 4.27c.95 1.22 2.63 3.19 1.75 4.95a3.73 3.73 0 01-2.19 1.68 10.75 10.75 0 01-8.82-1.46 9.14 9.14 0 01-4.46-7.32c-.29-2.34.22-4.83-.66-6.54s-2.06-2.33-2.38-4.69a3.59 3.59 0 011.35-2.9c1.87-1.79 2.95-3.92 3.48-3.21z'
            style={{
              transformOrigin: '76.878px 331.687px'
            }}
            id='prefix__el5zxv43rtgzu'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M74.67 338a7.73 7.73 0 019.17-.57 5.73 5.73 0 01.6.64c1 1.22 2.63 3.1 1.75 4.85a3.28 3.28 0 01-2.19 1.77 11.23 11.23 0 01-9-1.52 10.72 10.72 0 01-2.09-1.72l-.08-.08v-.07a6.36 6.36 0 011.84-3.3z'
            style={{
              transformOrigin: '79.636px 340.616px'
            }}
            id='prefix__el9fv7c5h6tyg'
            className='prefix__animable'
            fill='#fafafa'
          />
          <path
            d='M127.26 241.61c.79 12 1.38 27.49 1.38 27.49s-2.76 3-10.44 3.64-9.07 0-9.07 0l-.88-3.15-.89 3.84s-3.35 1.33-10.64 0c-8.18-1.48-9.3-4.59-9.3-4.59s.16-12 .75-18.61 3.54-17.39 3.54-17.39z'
            style={{
              transformOrigin: '108.03px 253.431px'
            }}
            id='prefix__ellcjgdxn5fb9'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M108.25 269.59l2.62-10.94a16.91 16.91 0 007.79-2s-1.64 3.29-6.8 3.95l-2.52 9-.21 3.15z'
            style={{
              transformOrigin: '113.455px 264.7px'
            }}
            id='prefix__elk7pxrgfknw8'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M100.53 195.26l7.2-.52c9.75-.66 12.84-11.09 14.31-16.52 7.69-9 .4-17.38-6.09-15.32-3.15-2.42-6.75-3.23-11.26-2.9a17.16 17.16 0 00-15.85 18.37l.08 1.25c.94 9.61 9.2 12.13 11.61 15.64z'
            style={{
              transformOrigin: '107.069px 177.595px'
            }}
            id='prefix__elp02he042ht'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M83 188.3c1.89-2 2.35-5 2.48-7.72a36.39 36.39 0 01.85-8.15c.57-2 2.13-4.27 5-3.76a2.09 2.09 0 001.76-2.34 4.77 4.77 0 00-1.35-.94 10.08 10.08 0 00-4.72-1.07c-.24 0-.48 0-.72.05a7.59 7.59 0 00-4.86 2.51c-2.33 2.75-2.06 6.58-3.47 9.73-1.66 3.68-5.73 4.69-9.47 4.94a4.24 4.24 0 00-2.44.62c-1 .82-1 2.45-.42 3.65a9.51 9.51 0 005.8 4.84c4.09 1.34 8.56.85 11.56-2.36z'
            style={{
              transformOrigin: '79.1774px 177.838px'
            }}
            id='prefix__el5ghb7yf0l16'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M153.13 240.82c3.27.83 6.56 1.59 9.89 2.16a23.26 23.26 0 005.76.44 9.63 9.63 0 005.36-2 5.27 5.27 0 001.79-2.62c.28-1 .56-3.24-.14-4-.95-1.09-2.26-.85-3.7-.76a36.76 36.76 0 01-4.2.09c-.25 0-2 .38-1.53-.15s1-.62 1.39-1.14a4.52 4.52 0 00.76-2.76.75.75 0 00-.14-.47.54.54 0 00-.57-.13 1.94 1.94 0 00-.54.31 11.9 11.9 0 01-4.63 1.93 11.5 11.5 0 01-4.23.48c-1-.2-15.05-5.45-18.35-7.3 0 0-6.42-13.1-9.69-19.94-3-6.2-8.36-6.25-12.82-6.11a24.45 24.45 0 002.51 13.15c4.87 9.37 9 19.13 11 21.29a9.87 9.87 0 004.27 2.57c4.41 1.52 9 2.62 13.46 3.83z'
            style={{
              transformOrigin: '146.837px 221.133px'
            }}
            id='prefix__elocnh9to4rut'
            className='prefix__animable'
            fill='#ffa8a7'
          />
          <path
            d='M116.49 198.83c1.05 0 3.6-.89 6.57-.33s5 1.73 7.31 6c2.15 4 3.82 7.71 3.82 7.71s-1.84 6.2-10.39 8.67c0 0-5.78-11.67-5.92-11.72s-1.39-10.33-1.39-10.33z'
            style={{
              transformOrigin: '125.34px 209.598px'
            }}
            id='prefix__elws05bmdb3k'
            className='prefix__animable'
            fill='#f19066'
          />
          <path
            d='M91.71 200.86c-3.26.58-3.89 3.85-3.79 7.15a52.42 52.42 0 001 8.42c.93 4.69 2.07 17 2.07 17l-2.57 12.45c5.23 5.7 27.79 8.65 39.22.3 0 0-1-28.86-1.81-34.62-1.16-7.79-4-11.81-7.55-12.6a15.7 15.7 0 00-3-.08l-13.79.53z'
            style={{
              transformOrigin: '107.775px 225.105px'
            }}
            id='prefix__el4dgx1s5moq'
            className='prefix__animable'
            fill='#f19066'
          />
          <path
            d='M91.71 200.86c-3.26.58-3.89 3.85-3.79 7.15a52.42 52.42 0 001 8.42c.93 4.69 2.07 17 2.07 17l-2.57 12.45c5.23 5.7 27.79 8.65 39.22.3 0 0-1-28.86-1.81-34.62-1.16-7.79-4-11.81-7.55-12.6a15.7 15.7 0 00-3-.08l-13.79.53z'
            style={{
              transformOrigin: '107.775px 225.105px'
            }}
            className='prefix__animable'
            fill='#fff'
            opacity={0.6}
            id='prefix__elgz7rxyi0mpc'
          />
          <path
            d='M100.52 183c-1.26 1.21-2.07.25-2.89-.6a2.55 2.55 0 00-4.2.88c-1.09 2.93 1.49 5.42 3 5.74 2.45.54 3.1-.68 3.1-.68l1.63 11.66c4.26 5.62 19.21 6.2 12.15-.7l-.31-3.61a15.6 15.6 0 004.64-.85c2-.71 3.39-2 4.46-5.22 1.44-4.26 1.3-8.78-.71-17.11a9.79 9.79 0 00-2.59-4.76c-.33 6.13-7.43 9.52-11.91 11.14a13.43 13.43 0 00-6.37 4.11z'
            style={{
              transformOrigin: '108.114px 186.048px'
            }}
            id='prefix__elb9kpa88fzjb'
            className='prefix__animable'
            fill='#ffa8a7'
          />
          <path
            d='M109.87 181.14a1.33 1.33 0 11-2.64.21 1.34 1.34 0 011.2-1.48 1.37 1.37 0 011.44 1.27z'
            style={{
              transformOrigin: '108.556px 181.284px'
            }}
            id='prefix__elezq4s8mjpz4'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M109.09 187.89l5.13 1.45a2.62 2.62 0 01-3.24 1.93 2.79 2.79 0 01-1.89-3.38z'
            style={{
              transformOrigin: '111.609px 189.626px'
            }}
            id='prefix__elzsfhos1j4te'
            className='prefix__animable'
            fill='#b16668'
          />
          <path
            d='M109.15 189.5a2.09 2.09 0 011.11-.32 2.2 2.2 0 012.21 2.06 2.52 2.52 0 01-1.49 0 2.73 2.73 0 01-1.83-1.74z'
            style={{
              transformOrigin: '110.81px 190.266px'
            }}
            id='prefix__el3c2yfhnzef4'
            className='prefix__animable'
            fill='#f28f8f'
          />
          <path
            d='M121.32 176l-3.13-.86a1.55 1.55 0 011.94-1.16 1.71 1.71 0 011.19 2.02z'
            style={{
              transformOrigin: '119.776px 174.96px'
            }}
            id='prefix__elyu5g1vv88r'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            d='M120.87 180.11a1.33 1.33 0 11-1.43-1.27 1.35 1.35 0 011.43 1.27z'
            style={{
              transformOrigin: '119.541px 180.167px'
            }}
            id='prefix__eldyqfd2s2k8'
            className='prefix__animable'
            fill='#263238'
          />
          <path
            style={{
              transformOrigin: '115.98px 182.88px'
            }}
            id='prefix__elb4lcj2rdce'
            className='prefix__animable'
            fill='#f28f8f'
            d='M113.71 179.37l.97 7.02 3.57-1.56-4.54-5.46z'
          />
          <path
            d='M113 195.73s-6.34-.13-9.34-1.72a7.86 7.86 0 01-3.06-2.57 7.88 7.88 0 001.88 3.17c3.09 3.1 10.65 2.66 10.65 2.66z'
            style={{
              transformOrigin: '106.865px 194.366px'
            }}
            id='prefix__elwbsqiinhmhq'
            className='prefix__animable'
            fill='#f28f8f'
          />
          <path
            d='M80.59 211.52c-1.24-.79-1.9-3.4-1.35-6.1s3.35-11.84 6.2-10.16 2.64 7.41 1.49 11.16-5.09 5.9-6.34 5.1z'
            style={{
              transformOrigin: '83.3845px 203.371px'
            }}
            id='prefix__el89gjbf78lr4'
            className='prefix__animable'
            fill='#f19066'
          />
          <path
            d='M80.59 211.52c-1.24-.79-1.9-3.4-1.35-6.1s3.35-11.84 6.2-10.16 2.64 7.41 1.49 11.16-5.09 5.9-6.34 5.1z'
            style={{
              transformOrigin: '83.3845px 203.371px'
            }}
            className='prefix__animable'
            opacity={0.2}
            id='prefix__el7lhejgp1jof'
          />
          <path
            d='M64.36 169c.3-3.36.68-6.72 1.24-10.05a23.72 23.72 0 011.5-5.58 9.64 9.64 0 013.64-4.4 5.3 5.3 0 013.06-.83c1.07.09 3.25.55 3.78 1.48.71 1.26.06 2.42-.51 3.74a37.17 37.17 0 00-1.47 3.93c-.07.25-.81 2.18-.16 1.91a1.07 1.07 0 00.38-.32 3.2 3.2 0 012.78-1 .76.76 0 01.54.27c.2.29 0 .68-.27 1a33 33 0 00-2.74 3.85 11.49 11.49 0 00-1.86 3.84c-.13 1 .16 16 .82 19.73 0 0 8.92 7.8 14.73 12.44a9.2 9.2 0 013.65 6.06c1.88 9.33-2.66 14.49-5 11.89 0 0-21.77-20.12-24.27-24.66a10 10 0 01-1-4.88c0-4.66.5-9.32.86-14 .05-1.42.17-2.95.3-4.42z'
            style={{
              transformOrigin: '78.5513px 182.884px'
            }}
            id='prefix__el4be6ravnsnp'
            className='prefix__animable'
            fill='#ffa8a7'
          />
          <path
            d='M94.24 200.49c-1.22-1.47-6.76-3.68-8.8-5.23 1.19 3.53-1.28 14.46-4.85 16.26 0 0 7.67 6.69 8.8 7.32s4-.44 5.16-5.38 1-11.4-.31-12.97z'
            style={{
              transformOrigin: '87.9598px 207.132px'
            }}
            id='prefix__elyc6nqlssjms'
            className='prefix__animable'
            fill='#f19066'
          />
        </g>
      </g>
      <g
        id='prefix__toy_reads--Rope--inject-63'
        className='prefix__animable'
        style={{
          transformOrigin: '257.65px 149.743px',
          animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideLeft',
          animationDelay: '0s'
        }}
      >
        <g
          id='prefix__toy_reads--rope--inject-63'
          className='prefix__animable'
          style={{
            transformOrigin: '257.65px 149.743px'
          }}
        >
          <path
            d='M351.29 251.4c-17.52-10.26-20.21-40.32-22.8-69.4l-.14-1.52C322.05 110.07 302.62 68 268.93 52c-37.1-17.68-69.61-5.16-80.84 9.37-9.06 11.74-16 31.89-5.16 99.3 6.37 39.69.73 65.86-16.76 77.76l-.46-.68c17.2-11.71 22.72-37.6 16.41-77-10.86-67.72-3.86-88 5.32-99.94 11.4-14.75 44.33-27.48 81.85-9.61 19.59 9.34 34.72 28.05 45 55.62 7.37 19.8 12.24 43.85 14.89 73.55l.14 1.52c2.58 28.87 5.24 58.71 22.4 68.75z'
            style={{
              transformOrigin: '258.715px 146.928px'
            }}
            id='prefix__el9tr00uitjic'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M346.47 244.15l10.37 7.51a1.16 1.16 0 01.37.48 4.43 4.43 0 01-.72 3.86c-.67.91-1.45 1.19-2 .78l-10.34-7.48h-.06a1.14 1.14 0 01-.37-.47 4.43 4.43 0 01.73-3.86c.75-.89 1.55-1.17 2.02-.82z'
            style={{
              transformOrigin: '350.466px 250.484px'
            }}
            id='prefix__el41ocoa9fm7i'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M355 252.51a4.44 4.44 0 00-.72 3.86c.43 1 1.47.84 2.31-.32a4.43 4.43 0 00.72-3.86c-.47-.98-1.5-.83-2.31.32z'
            style={{
              transformOrigin: '355.795px 254.286px'
            }}
            id='prefix__eloiokfo6sg2d'
            className='prefix__animable'
            fill='#37474f'
          />
          <path
            d='M168.8 232.18l-10.37 7.51a1.11 1.11 0 00-.37.47 4.43 4.43 0 00.72 3.86c.66.92 1.44 1.19 2 .78l10.22-7.41h.06a1.15 1.15 0 00.36-.48 4.41 4.41 0 00-.72-3.86c-.63-.93-1.38-1.22-1.9-.87z'
            style={{
              transformOrigin: '164.741px 238.511px'
            }}
            id='prefix__el611yskuddcl'
            className='prefix__animable'
            fill='#455a64'
          />
          <path
            d='M160.29 240.55a4.4 4.4 0 01.73 3.86c-.44 1-1.47.83-2.31-.33a4.43 4.43 0 01-.72-3.86c.43-.98 1.47-.83 2.3.33z'
            style={{
              transformOrigin: '159.505px 242.319px'
            }}
            id='prefix__el5lwl9atmeg9'
            className='prefix__animable'
            fill='#37474f'
          />
        </g>
      </g>
      <defs>
        <filter id='prefix__active' height='200%'>
          <feFlood floodColor='#32DFEC' floodOpacity={1} result='PINK' />
          <feComposite in='PINK' in2='DILATED' operator='in' result='OUTLINE' />
          <feMerge>
            <feMergeNode in='OUTLINE' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
        <filter id='prefix__hover' height='200%'>
          <feFlood floodColor='red' floodOpacity={0.5} result='PINK' />
          <feComposite in='PINK' in2='DILATED' operator='in' result='OUTLINE' />
          <feMerge>
            <feMergeNode in='OUTLINE' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
          <feColorMatrix values='0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0' />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
