import React, { useState } from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxHeight: 24) {
            ...GatsbyImageSharpFluid
            sizes
            presentationHeight
            presentationWidth
          }
        }
      }
    }
  `)
  const logoImg = data.file.childImageSharp.fluid

  const [navButtonClicked, setnavButtonClicked] = useState(false)
  const [burgerClass, setBurgerClass] = useState('navbar-burger burger')
  const [navClass, setNavClass] = useState('navbar-menu')

  const handleBtnClick = () => {
    setnavButtonClicked(!navButtonClicked)
    if (!navButtonClicked) {
      setNavClass('navbar-menu is-active has-background-primary')
      setBurgerClass('navbar-burger burger is-active')
    } else {
      setNavClass('navbar-menu')
      setBurgerClass('navbar-burger burger has-background-primary')
    }
  }
  return (

    <nav
      className='navbar is-primary is-fixed-top is-spaced'
      role='navigation'
      aria-label='main navigation'
    >
      <div className='container' >
        <div className='navbar-brand' >
          <Link to='/' className='navbar-item'>
            <Img
              fluid={logoImg}
              style={{
                marginRight: '5px',
                maxWidth: data.file.childImageSharp.fluid.presentationWidth,
                width: data.file.childImageSharp.fluid.presentationWidth,
                display: 'inline-block'
              }}
            />
            <h1>TOYREADS</h1>
          </Link>

          <a
            role='button'
            className={burgerClass}
            aria-label='menu'
            aria-expanded='false'
            data-target='navbarBasicExample'
            onClick={handleBtnClick}
          >
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            <span aria-hidden='true' />
          </a>
        </div>

        <div className={navClass}>
          <div
            className='navbar-end is-hidden-desktop'
            aria-hidden='true'
            style={{ padding: '0 20px' }}
          >
            <aside className='menu'>
              <Link
                to='/search'
                className='navbar-item'
                style={{ color: 'white' }}
              >
                Search
              </Link>
              <Link
                to='/reviews'
                className='navbar-item'
                style={{ color: 'white' }}
              >
                Reviews
              </Link>
              <Link
                to='/categories'
                className='navbar-item'
                style={{ color: 'white' }}
              >
                Categories
            </Link>
              <Link
                to='/who-are-we'
                className='navbar-item'
                style={{ color: 'white' }}
              >
                Who Are We?
            </Link>
            </aside>
          </div>
          <div className='navbar-end is-hidden-touch'>
            <Link
              to='/search'
              className='navbar-item'
              style={{ color: 'white' }}
            >
              Search
            </Link>
            <Link
              to='/reviews'
              className='navbar-item'
              style={{ color: 'white' }}
            >
              Reviews
              </Link>
            <Link
              to='/categories'
              className='navbar-item'
              style={{ color: 'white' }}
            >
              Categories
            </Link>
            <Link
              to='/who-are-we'
              className='navbar-item'
              style={{ color: 'white' }}
            >
              Who Are We?
            </Link>
          </div>
        </div>
      </div>
    </nav>

  )
}

export default Header
